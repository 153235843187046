$(function(){

    /*------------------------
    /* Cookies
    /------------------------*/
    function cookies() {
        var cookieName = 'sophiedkf-cookies',
        cookie = Cookies.get(cookieName),
        cookieContainer = $('.js-cookies'),
        closeCookies = $('.js-close-cookies');

        // Check if user has accepted cookie
        if (!cookie || cookie !== 'true') {
          cookieContainer.addClass('fadein');

          closeCookies.on('click', function() {
            cookieContainer.addClass('fadeout');
            
            setTimeout(function(){
              cookieContainer.hide();
            },500);

            Cookies.set(cookieName, 'true', { expires: 365 });
          });
        }
    }

    //-- Load Cookie.js library
    if (window.Cookies) {
        cookies();
    } else {
        var s = document.createElement('script');
        s.src = "https://cdn.jsdelivr.net/npm/js-cookie@2/src/js.cookie.min.js";

        s.addEventListener('load', cookies)
        document.head.appendChild(s);
    }
});