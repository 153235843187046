var Browser = {};

/**
 * [BrowserDetect description]
 * @type {Object}
 */
Browser.detect = {
    init: function () {
        this.browser = this.searchString(this.dataBrowser) || "Other";
        this.version = this.searchVersion(navigator.userAgent) || this.searchVersion(navigator.appVersion) || "Unknown";
    },
    searchString: function (data) {
        for (var i = 0; i < data.length; i++) {
            var dataString = data[i].string;
            this.versionSearchString = data[i].subString;

            if (dataString.indexOf(data[i].subString) !== -1) {
                return data[i].identity;
            }
        }
    },
    searchVersion: function (dataString) {
        var index = dataString.indexOf(this.versionSearchString);
        if (index === -1) {
            return;
        }

        var rv = dataString.indexOf("rv:");
        if (this.versionSearchString === "Trident" && rv !== -1) {
            return parseFloat(dataString.substring(rv + 3));
        } else {
            return parseFloat(dataString.substring(index + this.versionSearchString.length + 1));
        }
    },

    dataBrowser: [
        {string: navigator.userAgent, subString: "Edge", identity: "MS Edge"},
        {string: navigator.userAgent, subString: "MSIE", identity: "Explorer"},
        {string: navigator.userAgent, subString: "Trident", identity: "Explorer"},
        {string: navigator.userAgent, subString: "Firefox", identity: "Firefox"},
        {string: navigator.userAgent, subString: "Opera", identity: "Opera"},  
        {string: navigator.userAgent, subString: "OPR", identity: "Opera"},  

        {string: navigator.userAgent, subString: "Chrome", identity: "Chrome"}, 
        {string: navigator.userAgent, subString: "Safari", identity: "Safari"}       
    ]
};

/**
 * Block if is IE
 * @return void
 */
Browser.isExplorer = function(){
	var body = document.querySelector('body');
	body.classList.add('is-IE');
}


Browser.detect.init();
if(Browser.detect.browser == 'Explorer'){
	Browser.isExplorer();
}