var Menu = {};


//--------------
// Functions
//--------------

Menu.$menu = $('.js-menu');
Menu.$toggleMenuButton = $('.js-toggle-menu');
Menu.activeClass = 'is-active';

Menu.socialNetworks = '.js-main-social';
Menu.socialNetworksButton = '.js-main-social-button';
Menu.openClass = 'is-open';

/**
 * [Toggle Menu description]
 * @return {[type]} [description]
 */
Menu.toggleMenu = function(){
	Menu.$menu.toggleClass(Menu.activeClass);

	setTimeout(function(){
		Menu.$toggleMenuButton.toggleClass(Menu.activeClass);
		$(App.overlay).toggleClass('is-active');
	}, 1000);
}

Menu.closeMenu = function(){
	console.log('close menu');
	Menu.$menu.removeClass(Menu.activeClass);
	Menu.$toggleMenuButton.removeClass(Menu.activeClass);
	$(App.overlay).removeClass('is-active');
}

/**
 * Menu items
 * @return {void}
 */
Menu.menuItemsFade = function() {
	var menuItemsFade = new TimelineMax();

	menuItemsFade.staggerFromTo('.js-menu-item', 1.2, {opacity: 0, xPercent: 20}, {opacity: 1, xPercent: 0, ease: Quart.easeOut}, 0.1, 0.5);
}

/**
 * Infos items
 * @return {void}
 */
Menu.infosItemsFade = function() {
	var infosItemsFade = new TimelineMax();

	infosItemsFade.staggerFromTo('.js-infos-item', 1.2, {opacity: 0}, {opacity: 1, ease: Quart.easeOut}, 0.2, 1);
}


//--------------
// Execution
//--------------

Menu.init = function(){
	//-- Open / Close menu
	Menu.$toggleMenuButton.on('click', function(){
		Menu.toggleMenu();

		// Menu items fadein
		if(Menu.$menu.hasClass(Menu.activeClass)) {
			Menu.menuItemsFade();
			Menu.infosItemsFade();
		}
	});

	//-- Close menu on click on Overlay
	$('body').on('click', App.overlay, function(){
		Menu.closeMenu();
	});

}

Menu.init();


module.exports = Menu;
