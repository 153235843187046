var Project = {};

//--------------
// Variables
//--------------
Project.animationsInit = '';

Project.wrapper = '.js-project';
Project.banner = '.js-project-banner';
Project.bannerImg = '.js-project-banner-image';
Project.bannerContent = '.js-project-banner-content';

//--------------
// Methods
//--------------

/**
 * Banner Image animation
 * @return {void}
 */
Project.bannerImageAnim = function(element) {
  // TimelineMax
  Project.bannerImageAnimTl = new TimelineMax();
  Project.bannerImageAnimTl
    .fromTo(Project.bannerImg, 3, { scale: 1.3, y: 30, ease: Quart.easeOut }, { scale: 1.2, y: 0, ease: Quart.easeOut }, 0, 0.2);

  // Add to ScrollMagic
  Project.bannerImageAnimScene = new ScrollMagic.Scene({
      triggerElement: Project.wrapper,
      triggerHook: 0,
      reverse: false
    })
    .setTween(Project.bannerImageAnimTl)
    // .addIndicators({name: "position"})
    .addTo(Project.animationsController);
}

/**
 * Banner Image on scroll
 * @return {void}
 */
Project.bannerImageScroll = function(element) {
  // TimelineMax
  Project.bannerImageScrollTl = new TimelineMax();
  Project.bannerImageScrollTl
    .fromTo(Project.bannerImg, 1, { scale: 1.2, yPercent: 0, ease: Quart.easeOut }, { scale: 1, yPercent: 20, ease: Quart.easeOut }, 0);

  // Add to ScrollMagic
  Project.bannerImageScrollScene = new ScrollMagic.Scene({
      triggerElement: Project.wrapper,
      triggerHook: 0,
      duration: $(window).outerHeight() * 6
    })
    .setTween(Project.bannerImageScrollTl)
    // .addIndicators({name: "position"})
    .addTo(Project.animationsController);
}

/**
 * Header Colors
 * @return {void}
 */
Project.headerColors = function() {
  // Add to ScrollMagic
  Project.sceneHeaderColors = new ScrollMagic.Scene({
      triggerElement: Project.banner,
      duration: $(Project.banner).height(),
      triggerHook: 0
    })
    .on('enter', function() {
      console.log('in project banner');
      $('body').addClass('is-white-el');
    })
    .on('leave', function() {
      console.log('out project banner');
      $('body').removeClass('is-white-el');
    })
    // .addIndicators({name: "WIDTH"}) // add indicators (requires plugin)
    .addTo(Project.animationsController);
}


/**
 * On Resize
 * @return {void}
 */
Project.animationsInit = false;
Project.onResize = function() {
  var windowWidth = $(window).outerWidth();

  if (windowWidth > 767 && !Project.animationsInit) {
    // Set Body class
    App.setBodyClass(['is-white-el']);

    // Header colors
    Project.headerColors();

    // Banner Animation
    if ($('html').hasClass('no-touchevents')) {
      Project.bannerImageAnim();
      Project.bannerImageScroll();
    }

    Project.animationsInit = true;
  }

  // Mobile
  if (windowWidth < 768 && Project.animationsInit) {

    Project.animationsInit = false;
  }
}

/**
 * Init
 * @return {void}
 */
Project.init = function()  {
  // Animations Controller
  Project.animationsController = new ScrollMagic.Controller({
    refreshInterval: 1
  });

  // On resize
  // Set variable if desktop or tablet
  if ($(window).outerWidth() > 767) {
    Project.animationsInit = false;
  } else {
    Project.animationsInit = true;
  }

  Project.onResize();

  $(window).resize(function() {
    Project.onResize();
  });
}

//--------------
// Export
//--------------

module.exports = Project;