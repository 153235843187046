/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./modules/is-IE');
require('./bootstrap');
var jQuery = require('jquery');

window.App = {};
window.$ = jQuery;
window.jQuery = jQuery;

import Scrollbar from 'smooth-scrollbar';
var Modernizr = require('./plugins/modernizr');
import lozad from 'lozad';

//--------------
// Plugins
//--------------

//-- Ease effect on edge extension
class EdgeEasingPlugin extends Scrollbar.ScrollbarPlugin {
  transformDelta(delta) {
    const {
      limit,
      offset,
    } = this.scrollbar;

    const x = EdgeEasingPlugin._remainMomentum.x + delta.x;
    const y = EdgeEasingPlugin._remainMomentum.y + delta.y;

    // clamps momentum within [-offset, limit - offset]
    this.scrollbar.setMomentum(
      Math.max(-offset.x, Math.min(x, (limit.x - offset.x) + 20)),
      Math.max(-offset.y, Math.min(y, (limit.y - offset.y) + 20))
    );

    return { x: 0, y: 0 };
  }

  onRender(remainMomentum) {
    Object.assign(EdgeEasingPlugin._remainMomentum, remainMomentum);
  }
}
EdgeEasingPlugin.pluginName = 'edgeEasing';
EdgeEasingPlugin._remainMomentum = {
  x: 0,
  y: 0,
};

//--------------
// Variables
//--------------
var App = window.App;

App.mode = document.querySelector('body').getAttribute('data-mode');
App.Scrollbar = Scrollbar;
App.Scrollbar.use(EdgeEasingPlugin);
App.Barba = require('./plugins/barba');
App.Swiper = require('./plugins/swiper.min');

App.windowWidth = $(window).outerWidth();
App.windowHeight = $(window).outerHeight();
App.overlay = '.js-main-overlay';
App.scrollProgression = '.js-scroll-progress-fill';


//--------------
// Methods
//--------------

// Lazy load
window.Observer = lozad('.lazy', {
  loaded: function(el) {
    el.onload = function() {
      el.classList.add('is-loaded');

      setTimeout(function() {
        el.classList.remove('lazy');
        el.classList.remove('is-loaded');
      }, 1500);
    }
  }
});

window.dataLayer = window.dataLayer || [];
window.gtag = function() {
  dataLayer.push(arguments);
}

/**
 * On Resize
 * @return {void}
 */
App.scrollBarIsInit = false;
App.onResize = function() {

  //----------------------
  // Init ScrollMagic controller
  //----------------------
  // App.animationsController = new ScrollMagic.Controller({
  //   refreshInterval: 1
  // });

  // Desktop
  if (App.windowWidth > 991 && !App.scrollBarIsInit) {
    console.log('Main desktop');

    //----------------------
    // Smooth Scrollbar
    //----------------------

    // Destroy smooth scroll
    if (App.scrollbar) {
      App.scrollbar.destroy();
    }

    // Init Smooth Scrollbar
    App.scrollbar = App.Scrollbar.init(document.querySelector('.js-scroll-container'), {
      renderByPixels: true,
    });

    // Hack for ScrollMagic + Scrollbar
    // App.scrollBarY = 0;

    // // Update scrollY controller
    // if(App.isChrome){
    // 	App.animationsController.scrollPos(function () {
    // 		return App.scrollBarY;
    // 	});
    // }

    //-- Social Networks Display
    // On Load
    if ($('.scroll-content').height() <= App.windowHeight) {
      $('.js-main-social').addClass('is-open');
    }

    // On scroll
    App.scrollbar.addListener(function(status) {
      var scrollLimit = App.scrollbar.limit.y;
      var scrollTop = App.scrollbar.scrollTop;

      // Show social networks
      if (scrollTop >= scrollLimit) {
        $('.js-main-social').addClass('is-open');
      } else {
        $('.js-main-social').removeClass('is-open');
      }
    });

    //-- Progress Bar on scroll
    App.scrollbar.addListener(function(status) {
      var scrollContent = App.scrollbar.getSize().content.height;
      var scrollTop = App.scrollbar.scrollTop;

      var scrollPercent = (scrollTop / (scrollContent - App.windowHeight));
      var scrollPercentRounded = Math.round(scrollPercent * 100);

      $(App.scrollProgression).css({
        height: scrollPercentRounded + '%'
      });
    });

    App.scrollBarIsInit = true;
  }

  // Mobile + Tablet
  if (App.windowWidth < 992 && App.scrollBarIsInit) {
    console.log('Main tablet');

    // Destroy smooth scroll
    if (App.scrollbar) {
      App.scrollbar.destroy();
    }

    // Init Smooth Scrollbar
    App.scrollbar = App.Scrollbar.init(document.querySelector('.js-scroll-container'), {
      renderByPixels: true,
    });


    // Disable Smooth Scrollbar on touch devices
    if ($('html').hasClass('touchevents')) {
      // console.log('OKKK');
      $('.js-scroll-container').css({
        'overflow': 'auto',
        'min-height': 'auto',
        'height': 'auto',
      });
      App.scrollbar.destroy();
      // $('.scroll-content').css('transform', 'inherit');
    }

    // Only Mobile
    if (App.windowWidth < 768) {
      // Hide Menu button if scroll to bottom of page
      $(window).on('scroll', function() {
        if ($(window).scrollTop() + $(window).outerHeight() > ($(document).outerHeight() - $('.js-footer').outerHeight())) {
          $('.js-toggle-menu').fadeOut();
        } else {
          $('.js-toggle-menu').fadeIn();
        }
      });
    }

    App.scrollBarIsInit = false;
  }
}

// Onload delay - is-loaded class
App.delay = 1500;


/**
 * Init all scripts
 * @return {void}
 */
App.init = function() {

  // Reinit body class
  App.removeBodyClasses();
  App.setBodyClass(['body', 'loading']);
  $('html').removeClass('error-html');

  //----------------------
  // On Resize - init Scrollbar
  //----------------------
  // Set variable if desktop or tablet
  if (App.windowWidth > 991) {
    App.scrollBarIsInit = false;
  } else {
    App.scrollBarIsInit = true;
  }

  if (App.mode == 'production') {
    console.log = function() {};
    console.info = function() {};
    console.warn = function() {};
    console.error = function() {};

    // Analytics
    var pagePath = (window.location.href).replace(window.location.origin, '').toLowerCase();
    gtag('js', new Date());
    gtag('config', 'UA-165122009-1', {
      'anonymize_ip': true, // for GDPR
      'page_title': document.title,
      'page_path': pagePath
    });
  }

  App.onResize();

  $(window).on('resize', function() {
    App.onResize();
  });

  //----------------------
  // IE Detection
  //----------------------
  var ua = window.navigator.userAgent;
  var msie = ua.indexOf("MSIE ");
  if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
    $('body').addClass('is-ie');
  }

  //----------------------
  // Global
  //----------------------

  //-- Init lazy load images
  Observer.observe();

  //-- Page is loaded
  setTimeout(function() {
    $('body').addClass('is-loaded');
    App.delay = 500;
  }, App.delay);

  //-- Hover Link
  var $hoverLink = $('.js-hover-link');
  if ($hoverLink.length > 0) {
    App.hoverLink($hoverLink);
  }

  /* ----------------------
  /* COOKIES
  /---------------------- */
  require('./modules/cookies.js');

  //----------------------
  // Animations
  //----------------------
  var Animations = require('./modules/animations.js').init();

  //----------------------
  // Header
  //----------------------
  var $header = $('[data-module="header"]');
  if ($header.length > 0) {
    var Header = require('./modules/header.js');
  }

  //----------------------
  // Menu
  //----------------------
  var $menu = $('[data-module="menu"]');
  if ($menu.length > 0) {
    var Menu = require('./modules/menu.js');
  }

  //----------------------
  // Home
  //----------------------
  var $home = $('[data-module="home"]');
  if ($home.length > 0) {
    var Home = require('./pages/home.js').init();
  }

  //----------------------
  // Mainscreen
  //----------------------
  var $mainscreen = $('[data-module="mainscreen"]');
  if ($mainscreen.length > 0) {
    var Mainscreen = require('./modules/mainscreen.js').init();
  }

  //----------------------
  // Presentation block
  //----------------------
  var $presentation = $('[data-module="presentation"]');
  if ($presentation.length > 0) {
    var Presentation = require('./modules/presentation-block.js').init();
  }

  //----------------------
  // Projects
  //----------------------
  var $projects = $('[data-module="projects"]');
  if ($projects.length > 0) {
    var Projects = require('./pages/projects.js').init();
    if ($projects.hasClass('js-journal')) {
      App.setBodyClass(['projects-body', 'is-white-el']);

    } else {
      App.setBodyClass(['projects-body']);
    }
  }

  //----------------------
  // Project
  //----------------------
  var $project = $('[data-module="project"]');
  if ($project.length > 0) {
    var Project = require('./pages/project.js').init();
  }

  //----------------------
  // Experience
  //----------------------
  var $experience = $('[data-module="experience"]');
  if ($experience.length > 0) {
    var Experience = require('./pages/experience.js').init();
  }

  //----------------------
  // Prestations
  //----------------------
  var $prestations = $('[data-module="prestations"]');
  if ($prestations.length > 0) {
    var Prestations = require('./pages/prestations.js').init();
  }

  //----------------------
  // Push Web Project
  //----------------------
  var $pushWebproject = $('[data-module="push-webproject"]');
  if ($pushWebproject.length > 0) {
    var PushWebproject = require('./modules/push-webproject.js').init();
    App.setBodyClass(['has-push-webproject']);
  }

  //----------------------
  // Contact
  //----------------------
  var $contact = $('[data-module="contact"]');
  if ($contact.length > 0) {
    App.setBodyClass(['contact-body']);
  }

  //----------------------
  // Error 404
  //----------------------
  var $error404 = $('[data-module="error404"]');
  if ($error404.length > 0) {
    App.setBodyClass(['is-white-el', 'error-body']);
    $('html').addClass('error-html');
  }


  //-- Click on link when is current window
  var links = document.querySelectorAll('a[href]');
  var cbk = function(e) {
    if (e.currentTarget.href === window.location.href) {
      Menu.closeMenu();
      Header.closeSocialNetworks();
      e.preventDefault();
    }
  };

  for (var i = 0; i < links.length; i++) {
    console.log(links[i]);
    links[i].addEventListener('click', cbk);
  }
  //-- End click on link == current window


  //----------------------
  // Barba -- Destroy then Init
  //----------------------
  App.Barba.destroy();
  App.Barba.init({
    timeout: 5000,
    // prevent: ({ el }) => el.classList && el.classList.contains('js-menu-lang'),
    schema: {
      prefix: 'data-anim'
    },
    transitions: [
      // Global transition
      {
        name: 'Global transitions',
        custom: ({ current, next, trigger }) =>
          trigger.classList && trigger.classList.contains('js-link') ||  trigger === 'popstate',
        leave({ current, next, trigger }) {
          console.log('LEAVE CURRENT', current);
          console.log('LEAVE NEXT', next);

          return new Promise(resolve => {
            $(current.container).fadeOut(function() {
              $(current.container).remove();
            });

            // Header init
            Menu.closeMenu();
            Header.closeSocialNetworks();

            setTimeout(function() {
              $(App.scrollProgression).css({
                height: 0
              });
            }, 500);

            $('body').addClass('is-transition');
            setTimeout(function() {
              $('body').addClass('is-transition-out');
              $('body').removeClass('is-transition');
            }, 1000);

            setTimeout(() => {
              resolve();
            }, 500);
          });
        },
        enter({ current, next, trigger }) {
          console.log('ENTER CURRENT', current);
          console.log('ENTER NEXT', next);
          $(next.container).hide();

          return new Promise(resolve => {
            $(next.container).fadeIn();
            App.init();
          });
        }
      },
    ]
  });
  // End Barba

}


//--------------
// Function
//--------------
/**
 * Hover Link
 * @return {void}
 */
App.hoverLink = function(link) {
  $(link).hover(function() {
    $(link).addClass('is-not-hover');
    $(this).removeClass('is-not-hover').addClass('is-hover');
  }, function() {
    $(link).removeClass('is-not-hover is-hover');
  });
}

/**
 * [setBodyClass description]
 * @param {[type]} classes  [description]
 * @param {Array}  toRemove [description]
 */
App.setBodyClass = function(classes, toRemove = []) {
  toRemove.forEach(function(_class) {
    $('body').removeClass(_class);
  });

  classes.forEach(function(_class) {
    $('body').removeClass(_class);
    $('body').addClass(_class);
  });
}

/**
 * [removeBodyClasses description]
 * @return {[type]} [description]
 */
App.removeBodyClasses = function() {
  var classes = $('body').attr('class').split(/\s+/);

  App.setBodyClass([], classes);
}

//--------------
// Execution
//--------------

$(function() {
  App.init();

  //-- Loader only onload
  $('body').addClass('loading');
  // App.prestationTimer = App.delay;

  // Prestations animation
  setTimeout(function() {
    if ($('.js-prestations-list').length > 0) {
      console.log('PRESTATIONS ANIMATION');
      var itemsFadeTl = new TimelineMax();
      itemsFadeTl.staggerFromTo('.js-prestation-item', 1.2, { opacity: 0, y: 40 }, { opacity: 1, y: 0, ease: Quart.easeOut }, 0.1, 0);
    }
  }, App.delay);
});