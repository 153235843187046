var Animations = {};


//--------------
// Functions
//--------------


/**
 * [White Elements description]
 * @return {[type]} [description]
 */
Animations.activeClass = function(element, triggerHook){
	var triggerHook = (typeof triggerHook == 'undefined') ? '0.7' : triggerHook;

	var sceneActiveClass = new ScrollMagic.Scene({
	    triggerElement: element,
	    triggerHook: triggerHook,
	    reverse: false
	})
	// .setTween(PushWebproject.tlFixedImagey)
	.setClassToggle(element, 'is-active')
	// .addIndicators({name: "webproject"})
	.addTo(Animations.animationsController);
}

/**
 * Fadein (one element)
 * @return {void}
 */
Animations.fadein = function(element, triggerElement, direction, duration, start, end, delay, offset) {
	var fadein = new TimelineMax();

	// Fade to Top
	if(direction == 'horizontal') {
		fadein.staggerFromTo($(element), duration, {opacity: 0, x: start}, {opacity: 1, x: end, force3D:true, ease: Quart.easeOut}, 0.15, delay);
	}

	if(direction == 'vertical') {
		fadein.staggerFromTo($(element), duration, {opacity: 0, y: start}, {opacity: 1, y: end, force3D:true, ease: Quart.easeOut}, 0.15, delay);
	}

    var scene = new ScrollMagic.Scene({
		triggerElement: element,
		triggerHook: offset,
		reverse: false
	})
	.setTween(fadein)
	.addTo(Animations.animationsController);
}

/**
 * Destroy animations
 * @return {void}
 */
Animations.destroyAnimations = function() {
  // Image
  // TweenMax.killTweensOf(PushWebproject.image);﻿
  // TweenMax.set(PushWebproject.image, { clearProps: 'all' });
}

/**
 * On Resize
 * @return {void}
 */
Animations.onResize = function(){
	var windowWidth = $(window).outerWidth();

	if(windowWidth > 991 && !Animations.animationsInit) {
		console.log('anim desktop');

		Animations.animationsInit = true;
	}

	// Mobile
	if(windowWidth < 992 && Animations.animationsInit) {
		console.log('anim desktop');

		// Animations.destroyAnimations();

		// Remove Experience blockquote animation
		TweenMax.killTweensOf('.experience .js-blockquote');﻿
		TweenMax.set('.experience .js-blockquote', { clearProps: 'all' });

		Animations.animationsInit = false;
	}
}



//--------------
// Execution
//--------------

Animations.init = function(){

	//-- Animations Controller
	Animations.animationsController = new ScrollMagic.Controller({
		refreshInterval: 2
	});

	if($('.js-blockquote').length > 0) {
		$('.js-blockquote').each(function(){
			// element, triggerElement, direction, duration, start, end, delay, offset
			Animations.fadein(this, this, 'vertical', 2, 100, 0, 0, 0.8);
		});
	}

	if($('.js-fadein').length > 0) {
		$('.js-fadein').each(function(){
			// element, triggerElement, direction, duration, start, end, delay, offset
			Animations.fadein(this, this, 'vertical', 1.2, 20, 0, 0, 0.7);
		});
	}

	if($('.js-push-contact-el').length > 0) {	
		Animations.fadein('.js-push-contact-el', '.js-push-contact-el', 'vertical', 1.8, 50, 0, 0, 0.7);
	}

	if($('.js-anim-active').length > 0) {
		$('.js-anim-active').each(function(){
			Animations.activeClass(this);
		});
	}

	if($('.js-fade-to-top').length > 0) {	
		$('.js-fade-to-top').each(function(){
			Animations.fadein(this, this, 'vertical', 1.2, 60, 0, 0, 0.8);
		});
	}

	if($('.js-experience-image').length > 0) {	
		$('.js-experience-image').each(function(){
			Animations.fadein(this, this, 'vertical', 1.8, 60, 0, 0.4, 0.8);
		});
	}

	if($('.js-contact-el').length > 0) {
		Animations.fadein('.js-contact-el', '.contact', 'vertical', 1.2, 20, 0, 0.1, 0.85);
	}

	// On resize
	// Set variable if desktop or tablet
	if($(window).outerWidth() > 991) {
		Animations.animationsInit = false;
	} else {
		Animations.animationsInit = true;
	}
	Animations.onResize();

	$(window).resize(function(){
		Animations.onResize();
	});

}


module.exports = Animations;
