var Projects = {};

//--------------
// Variables
//--------------
Projects.carousel = '.js-projects-swiper';
Projects.carouselSlide = '.js-projects-slide';
Projects.carouselSwiper = '';

//--------------
// Methods
//--------------


/**
 * Projects - init Carousel
 * @return {void}
 */
Projects.initCarousel = function() {
  console.log('init porfolio carousel');
  var space = 40;
  var space_md = 20;
  if ($(Projects.carouselSlide).length < 4) {
    space = 20;
  }
  if ($(Projects.carouselSlide).length == 3) {
    space_md = 40;
  }
  if ($(Projects.carouselSlide).length == 2) {
    space_md = 10;
  }
  Projects.carouselSwiper = new App.Swiper(Projects.carousel, {
    slidesPerView: 'auto',
    spaceBetween: 40,
    centeredSlides: false,
    speed: 800,
    freeMode: true,
    watchSlidesProgress: true,
    mousewheel: true,
    slidesOffsetBefore: space,
    slidesOffsetAfter: space,
    pagination: {
      el: '.js-projects-progressbar',
      type: 'progressbar',
    },
    breakpoints: {
      991: {
        spaceBetween: 20,
        slidesOffsetBefore: space_md,
        slidesOffsetAfter: space_md,
      }
    }
  });
}


/**
 * On Resize
 * @return {void}
 */
Projects.animationsInit = false;
Projects.onResize = function() {
  var windowWidth = $(window).outerWidth();

  if (windowWidth > 767 && !Projects.animationsInit) {
    Projects.initCarousel();

    // Add class if less than 4 projects
    if (Projects.carouselSwiper.slides.length < 4) {
      $(Projects.carousel).addClass('is-center').addClass('has-' + Projects.carouselSwiper.slides.length);
    }

    Projects.animationsInit = true;
  }

  // Mobile
  if (windowWidth < 768 && Projects.animationsInit) {
    if (Projects.carouselSwiper != '') {
      Projects.carouselSwiper.destroy(true, true);
    }

    Projects.animationsInit = false;
  }
}

/**
 * Init
 * @return {void}
 */
Projects.init = function() {

  //-- On resize

  // Set variable if desktop or tablet
  if (App.windowWidth > 767) {
    Projects.animationsInit = false;
  } else {
    Projects.animationsInit = true;
  }

  Projects.onResize();

  $(window).resize(function() {
    Projects.onResize();
  });


}

//--------------
// Export
//--------------

module.exports = Projects;