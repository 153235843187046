var Home = {};

//--------------
// Variables
//--------------

//--------------
// Methods
//--------------
Home.mainscreen = '.js-mainscreen';
Home.mainscreenLeftImg = '.js-mainscreen-left-img';

Home.projectsGrid = '.js-projects-grid';
// Home.projectsTitle = '.js-home-projects-title';

Home.pushContact = '.js-home-push-contact';


/**
 * Home - Fixed Projects Title
 * @return {void}
 */
Home.fixedProjectsTitle = function() {
  Home.projectsTitleEndPosition = 500;
  Home.projectsTitleHeight = $(Home.projectsTitle).outerHeight();

  // Add to ScrollMagic
  Home.projectsTitleScene = new ScrollMagic.Scene({
      triggerElement: Home.projectsGrid,
      triggerHook: 0.35,
      duration: $(Home.projectsGrid).height() - Home.projectsTitleEndPosition + Home.projectsTitleHeight / 2,
    })
    .on('enter', function() {
      // Fixed Text with Scrollbar
      App.scrollbar.addListener(function(status) {
        var offset = status.offset;

        $(Home.projectsTitle).css({
          top: offset.y + $(window).height() / 2,
          bottom: 'auto'
        });
      });
    })
    .on('leave', function() {
      App.scrollbar.addListener(function(status) {
        var offset = status.offset;
        var windowHeight = $(window).outerHeight();
        var projectsGridTop = $(Home.projectsGrid).offset().top + offset.y + windowHeight * 0.35;

        if (offset.y > projectsGridTop) {
          // Scroll down
          $(Home.projectsTitle).css({
            top: 'auto',
            bottom: Home.projectsTitleEndPosition
          });
        } else {
          // Scroll up
          $(Home.projectsTitle).css({
            top: 0,
            bottom: 'auto'
          });
        }
      });
    })
    .setClassToggle(Home.projectsTitle, 'is-fixed')
    // .addIndicators({name: "position"})
    .addTo(Home.animationsController);
}

/**
 * Mainscreen Image on scroll
 * @return {void}
 */
Home.bannerImageScroll = function(element) {
  // TimelineMax
  Home.bannerImageScrollTl = new TimelineMax();
  Home.bannerImageScrollTl
    .fromTo(Home.mainscreenLeftImg, 1, { yPercent: 0, ease: Quart.easeOut }, { yPercent: 50, ease: Quart.easeOut }, 0);

  // Add to ScrollMagic
  Home.bannerImageScrollScene = new ScrollMagic.Scene({
      triggerElement: Home.mainscreen,
      triggerHook: 0,
      duration: $(window).outerHeight() * 4
    })
    .setTween(Home.bannerImageScrollTl)
    // .addIndicators({name: "position"})
    .addTo(Home.animationsController);
}


/**
 * Home - Projects Grid Image translation on scroll
 * @return {void}
 */
Home.projectsGridImageScroll = function(element) {
  var triggerElement = (typeof triggerElement == 'undefined') ? element : '#' + $(element).attr('id');

  // TimelineMax
  Home.projectsGridImageTl = new TimelineMax();
  Home.projectsGridImageTl
    .fromTo(element, 1, { y: -230, ease: Quart.easeOut }, { y: 130, ease: Quart.easeOut }, 0);

  // Add to ScrollMagic
  Home.projectsImageScene = new ScrollMagic.Scene({
      triggerElement: triggerElement,
      triggerHook: 1,
      duration: $(window).height() * 4
    })
    .setTween(Home.projectsGridImageTl)
    // .addIndicators({name: "position"})
    .addTo(Home.animationsController);
}

/**
 * Home - Change Background Color
 * @return {void}
 */
Home.changeBgColor = function() {
  // Add to ScrollMagic
  Home.scene = new ScrollMagic.Scene({
      triggerElement: Home.pushContact,
      triggerHook: 0.5
    })
    .setClassToggle('body', 'background-main-bg')
    // .addIndicators({name: "WIDTH"}) // add indicators (requires plugin)
    .addTo(Home.animationsController);
}

/**
 * Destroy animations
 * @return {void}
 */
Home.destroyAnimations = function() {
  // Title
  TweenMax.killTweensOf(Home.mainscreenLeftImg);
  TweenMax.set(Home.mainscreenLeftImg, { clearProps: 'all' });
}

/**
 * On Resize
 * @return {void}
 */
Home.animationsInit = false;
Home.onResize = function() {
  var windowWidth = $(window).outerWidth();

  if (windowWidth > 991 && !Home.animationsInit) {

    // Banner Animation
    Home.bannerImageScroll();

    // Projects Grid Image on Scroll
    if ($('.js-projects-grid-image').length > 0) {
      $('.js-projects-grid-image').each(function() {
        Home.projectsGridImageScroll(this);
      });
    }

    Home.animationsInit = true;
  }

  // Mobile
  if (windowWidth < 992 && Home.animationsInit) {
    // Destroy animations
    Home.destroyAnimations();

    Home.animationsInit = false;
  }
}


/**
 * Init
 * @return {void}
 */
Home.init = function()  {
  // Animations Controller
  Home.animationsController = new ScrollMagic.Controller({
    refreshInterval: 1
  });

  // Change Background Color
  Home.changeBgColor();

  // Fixed Projects Title
  // Home.fixedProjectsTitle();

  // On resize
  // Set variable if desktop or tablet
  if ($(window).outerWidth() > 991) {
    Home.animationsInit = false;
  } else {
    Home.animationsInit = true;
  }

  Home.onResize();

  $(window).on('resize', function() {
    Home.onResize();
  });
}

//--------------
// Export
//--------------

module.exports = Home;