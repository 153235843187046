var Presentation = {};

//--------------
// Variables
//--------------
// Presentation.wrapper = '.js-mainscreen';

Presentation.animationsInit = '';
Presentation.Section = '.js-presentation-block';
Presentation.MainImage = '.js-presentation-block-main-image';
Presentation.SecondImage = '.js-presentation-block-secondary-image';

//--------------
// Methods
//--------------


/**
 * Title Opacity
 * @return {void}
 */
Presentation.imageTranslation = function() {
  // TimelineMax
  Presentation.tlImagePos = new TimelineMax();
  Presentation.tlImagePos
    .fromTo(Presentation.MainImage, 1, { y: '0%', ease: Quart.easeOut }, { y: '45%', ease: Quart.easeOut }, 0);

  // Add to ScrollMagic
  Presentation.scene = new ScrollMagic.Scene({
      triggerElement: Presentation.Section,
      duration: $(Presentation.Section).outerHeight() * 2,
      triggerHook: 0.75
    })
    .setTween(Presentation.tlImagePos)
    // .addIndicators({ name: "SCROLL" }) // add indicators (requires plugin)
    .addTo(Presentation.animationsController);
}


/**
 * Destroy animations
 * @return {void}
 */
Presentation.destroyAnimations = function() {
  // Image
  TweenMax.killTweensOf(Presentation.MainImage);
  TweenMax.set(Presentation.MainImage, { clearProps: 'all' });
}

/**
 * On Resize
 * @return {void}
 */
Presentation.animationsInit = false;
Presentation.onResize = function() {
  var windowWidth = $(window).outerWidth();

  if (windowWidth > 991 && !Presentation.animationsInit) {

    // Second Image Position
    var sectionHeight = $(Presentation.Section).height() + parseInt($(Presentation.Section).css('padding-bottom'));
    var secondImageHeight = $(Presentation.SecondImage).find('.js-image').outerHeight();
    var secondImagePos = sectionHeight - secondImageHeight;

    if (sectionHeight > secondImageHeight) {
      $(Presentation.SecondImage).css('top', secondImagePos);
    }

    // Image on Scroll
    Presentation.imageTranslation();

    Presentation.animationsInit = true;
  }

  // Mobile
  if (windowWidth < 992 && Presentation.animationsInit) {
    // Destroy Animations
    Presentation.destroyAnimations();

    Presentation.animationsInit = false;
  }
}

/**
 * Init
 * @return {void}
 */
Presentation.init = function()  {
  // Animations Controller
  Presentation.animationsController = new ScrollMagic.Controller({
    refreshInterval: 1
  });

  //-- On resize
  // Set variable if desktop or tablet
  if ($(window).outerWidth() > 767) {
    Presentation.animationsInit = false;
  } else {
    Presentation.animationsInit = true;
  }
  Presentation.onResize();

  $(window).on('resize', function() {
    Presentation.onResize();
  });
}

//--------------
// Export
//--------------

module.exports = Presentation;