var Experience = {};

//--------------
// Variables
//--------------
Experience.page = '.js-experience-page';
Experience.banner = '.js-experience';
Experience.bannerContent = '.js-experience-content';
Experience.bannerTitle = '.js-experience-title';
Experience.bannerText = '.js-experience-text';
Experience.bannerImage = '.js-experience-image';
Experience.BlockLink = '.js-experience-block-link';

Experience.imageMiddlePos = null;
Experience.innerImageMiddlePos = null;

Experience.animationsInit = '';

//--------------
// Methods
//--------------

/**
 * Experience Banner - Image Translation
 * @return {void}
 */
Experience.bannerImageTranslation = function() {
  // TimelineMax
  Experience.tlBannerImageTranslation = new TimelineMax();
  Experience.tlBannerImageTranslation
    .fromTo(Experience.bannerImage, 1, { y: 0, ease: Quart.easeOut }, { y: -150, ease: Quart.easeOut }, 0);

  // Add to ScrollMagic
  Experience.scene = new ScrollMagic.Scene({
      triggerElement: Experience.banner,
      duration: $(Experience.banner).height() * 2,
      triggerHook: 0
    })
    .setTween(Experience.tlBannerImageTranslation)
    // .addIndicators({name: "WIDTH"}) // add indicators (requires plugin)
    .addTo(Experience.animationsController);
}

/**
 * Experience Banner - Texts Translation
 * @return {void}
 */
Experience.bannerTextTranslation = function() {
  var fixedElementHeight = $(Experience.bannerTitle).outerHeight() + $(Experience.bannerText).outerHeight();
  // console.log(fixedElementHeight);
  // TimelineMax
  Experience.tlTextTranslation = new TimelineMax();
  Experience.tlTextTranslation
    .fromTo(Experience.bannerTitle, 1, { y: '-50%', ease: Quart.easeOut }, { y: -$(Experience.banner).outerHeight() * 3 }, 0)
    .fromTo(Experience.bannerText, 1, { y: 0, ease: Quart.easeOut }, { y: -$(Experience.banner).outerHeight() * 3 }, 0);

  // Add to ScrollMagic
  Experience.scene = new ScrollMagic.Scene({
      triggerElement: '.trigger-anim',
      duration: $(Experience.banner).outerHeight() * 3,
      triggerHook: 0.2
    })
    .setTween(Experience.tlTextTranslation)
    // .addIndicators({ name: "SCROLL" }) // add indicators (requires plugin)
    .addTo(Experience.animationsController);
}


/**
 * Experience - Change Background Color
 * @return {void}
 */
Experience.changeBgColor = function(triggerhook) {
  // Add to ScrollMagic
  Experience.scene = new ScrollMagic.Scene({
      triggerElement: Experience.BlockLink,
      triggerHook: triggerhook
    })
    // .on('enter', function () {
    // 	$('body').removeClass('is-white-el');
    //   })
    // .on('leave', function () {
    // 	$('body').addClass('is-white-el');
    //   })
    .setClassToggle(Experience.page, 'is-main-bg')
    // .addIndicators({name: "WIDTH"}) // add indicators (requires plugin)
    .addTo(Experience.animationsController);
}

/**
 * Destroy animations
 * @return {void}
 */
Experience.destroyAnimations = function() {
  // Image
  TweenMax.killTweensOf(Experience.bannerImage);
  TweenMax.set(Experience.bannerImage, { clearProps: 'all' });

  // Title
  TweenMax.killTweensOf(Experience.bannerTitle);
  TweenMax.set(Experience.bannerTitle, { clearProps: 'all' });

  // Text
  TweenMax.killTweensOf(Experience.bannerText);
  TweenMax.set(Experience.bannerText, { clearProps: 'all' });
}

/**
 * On Resize
 * @return {void}
 */
Experience.animationsInit = false;
Experience.onResize = function() {
  var windowWidth = $(window).outerWidth();
  // Experience.imageMiddlePos = $(Experience.bannerImage).offset().top + $(Experience.bannerImage).outerHeight()/2;
  Experience.imageMiddlePos = $(window).outerHeight() / 1.5;
  Experience.titleHeight = $(Experience.bannerTitle).outerHeight();
  Experience.innerImageMiddlePos = $(Experience.bannerImage).outerHeight() / 2;
  Experience.innerImagePosTop = $(Experience.bannerImage).position().top;

  // var titlePos = Experience.imageMiddlePos * 0.85;
  var titlePos = Experience.innerImageMiddlePos + Experience.innerImagePosTop + Experience.titleHeight * 0.25;
  if(Experience.innerImageMiddlePos == 0) {
    titlePos = $(window).outerHeight() / 2;
  }

  console.log('image pos', Experience.innerImageMiddlePos);

  if (windowWidth > 767 && !$('body').hasClass('is-ie')) {
    App.scrollbar.scrollTo(0, 0, 0);

    setTimeout(function() {
      // Texts initial positions
      $(Experience.bannerTitle).css({ top: titlePos });
      $(Experience.bannerText).css({ top: Experience.imageMiddlePos * 0.85 + $(Experience.bannerTitle).outerHeight() * 0.85 });

      Experience.tlTitleShow = new TimelineMax();
      Experience.tlTitleShow
        .fromTo(Experience.bannerTitle, 1, { opacity: 0, ease: Quart.easeOut }, { opacity: 1 }, 0.5)

      App.scrollbar.addListener(function(status) {
        var offset = status.offset;
        var titleHeight = $(Experience.bannerTitle).outerHeight();

        $(Experience.bannerTitle).css({ top: offset.y + titlePos });
        $(Experience.bannerText).css({ top: offset.y + Experience.imageMiddlePos * 0.85 + titleHeight * 0.85 });
      });
    }, 0);

    setTimeout(function() {
      // Experience wrapper height
      $(Experience.banner).css({ height: $(Experience.bannerText).outerHeight() + Experience.textTop + 200 });

      // Scroll Banner Text
      Experience.bannerTextTranslation();
    }, 300);
  }

  if (windowWidth > 767 && !Experience.animationsInit && !$('body').hasClass('is-ie')) {

    // Fixed Banner Text Position
    // $(Experience.bannerTitle).css({top : Experience.imageMiddlePos});
    // $(Experience.bannerText).css({top : Experience.imageMiddlePos + $(Experience.bannerTitle).outerHeight()*1.3});

    // // Fixed Text with Scrollbar
    // App.scrollbar.addListener(function(status) {
    // 	var offset = status.offset;
    // 	var titleHeight = $(Experience.bannerTitle).outerHeight();

    // 	$(Experience.bannerTitle).css({top : offset.y + Experience.imageMiddlePos});
    // 	$(Experience.bannerText).css({top : offset.y + Experience.imageMiddlePos + titleHeight*1.3});
    // });

    // // Scroll Banner Text
    // Experience.bannerTextTranslation();
    // // Image Translation
    Experience.bannerImageTranslation();

    Experience.animationsInit = true;
  }

  // Mobile
  if (windowWidth < 768 && Experience.animationsInit || $('body').hasClass('is-ie')) {
    // Destroy Animations
    Experience.destroyAnimations();

    // Fixed Banner Text Position
    $(Experience.bannerTitle).css({ top: Experience.innerImageMiddlePos });

    // Experience wrapper height
    $(Experience.banner).css({ 'height': 'auto' });

    Experience.animationsInit = false;
  }
}

/**
 * Init
 * @return {void}
 */
Experience.init = function()  {
  // Set Body class
  // App.setBodyClass(['is-white-el']);

  // Animations Controller
  Experience.animationsController = new ScrollMagic.Controller({
    refreshInterval: 1
  });

  // Change Background Color
  if ($(window).outerWidth() > 991) {
    Experience.changeBgColor(0.8);
  }
  if ($(window).outerWidth() > 767 && $(window).outerWidth() < 992) {
    Experience.changeBgColor(0.85);
  }
  if ($(window).outerWidth() < 768) {
    Experience.changeBgColor(0.6);
  }

  // Top of Text - used for Experience wrapper height
  setTimeout(function() {
    Experience.textTop = $(Experience.bannerText).offset().top;
  }, 200);

  //-- On resize
  // Set variable if desktop or tablet
  if ($(window).outerWidth() > 767) {
    Experience.animationsInit = false;
  } else {
    Experience.animationsInit = true;
  }

  setTimeout(function() {
    Experience.onResize();
  }, 100);

  $(window).on('resize', function() {
    Experience.onResize();
  });
}

//--------------
// Export
//--------------

module.exports = Experience;