var PushWebproject = {};


//--------------
// Functions
//--------------

PushWebproject.module = '.js-push-webproject';
PushWebproject.image = '.js-push-webproject-bckg';
PushWebproject.animationsInit = '';

/**
 * [White Elements description]
 * @return {[type]} [description]
 */
PushWebproject.whiteElements = function() {
  var sceneWhiteElements = new ScrollMagic.Scene({
      triggerElement: PushWebproject.module,
      triggerHook: 0.05,
    })
    // .setTween(PushWebproject.tlFixedImagey)
    .setClassToggle('body', 'is-white-el')
    // .addIndicators({name: "webproject"})
    .addTo(PushWebproject.animationsController);
}


/**
 * [Fixed Image description]
 * @return {[type]} [description]
 */
PushWebproject.fixedImage = function() {
  // TimelineMax
  PushWebproject.tlFixedImage = new TimelineMax();
  PushWebproject.tlFixedImage
    .fromTo(PushWebproject.image, 1, { width: '80%', ease: Quart.easeOut }, { width: '100%', ease: Quart.easeOut }, 0);

  var sceneFixedImage = new ScrollMagic.Scene({
      triggerElement: PushWebproject.module,
      triggerHook: 0.05,
    })
    .on('enter', function() {
      var windowWidth = $(window).outerWidth();

      if (windowWidth > 991) {
        console.log('coucou ', windowWidth);
        App.scrollbar.addListener(function(status) {
          var offset = status.offset;

          $(PushWebproject.image).css({
            position: 'fixed',
            top: offset.y,
            left: '50%'
          });
        });
      }
    })
    .on('leave', function() {
      App.scrollbar.addListener(function(status) {
        $(PushWebproject.image).css({
          position: 'absolute',
          top: 0
        });
      });
    })
    // .setTween(PushWebproject.tlFixedImagey)
    .setClassToggle(PushWebproject.module, 'is-fixed')
    // .addIndicators({name: "webproject"})
    .addTo(PushWebproject.animationsController);
}

/**
 * Destroy animations
 * @return {void}
 */
PushWebproject.destroyAnimations = function() {
  // Image
  TweenMax.killTweensOf(PushWebproject.image);﻿
  TweenMax.set(PushWebproject.image, { clearProps: 'all' });
}

/**
 * On Resize
 * @return {void}
 */
PushWebproject.onResize = function() {
  var windowWidth = $(window).outerWidth();

  if (windowWidth > 991 && !PushWebproject.animationsInit && !$('body').hasClass('is-ie')) {
    console.log('push web desktop');

    //-- Fixed Background Image
    PushWebproject.fixedImage();

    PushWebproject.animationsInit = true;
  }

  // Mobile
  if (windowWidth < 992 && PushWebproject.animationsInit || $('body').hasClass('is-ie')) {
    console.log('push web MOBILE');

    PushWebproject.destroyAnimations();
    $(PushWebproject.module).removeClass('is-fixed');

    PushWebproject.animationsInit = false;
  }
}



//--------------
// Execution
//--------------

PushWebproject.init = function() {

  //-- Animations Controller
  PushWebproject.animationsController = new ScrollMagic.Controller({
    refreshInterval: 2
  });

  if ($(window).outerWidth() > 767) {
    //-- White Elements on scroll
    PushWebproject.whiteElements();
  }

  // On resize
  // Set variable if desktop or tablet
  if ($(window).outerWidth() > 991) {
    PushWebproject.animationsInit = false;
  } else {
    PushWebproject.animationsInit = true;
  }
  PushWebproject.onResize();

  $(window).on('resize', function() {
    PushWebproject.onResize();
  });

}

// PushWebproject.init();


module.exports = PushWebproject;