var Prestations = {};

//--------------
// Variables
//--------------
Prestations.wrapper = '.js-collapse';
Prestations.title = '.js-prestation-title';
Prestations.button = '.js-collapse-button';
Prestations.detail = '.js-collapse-content';
Prestations.image = '.js-prestation-image';
Prestations.item = '.js-prestation-item';
Prestations.editor = '.js-prestations-editor';


//--------------
// Methods
//--------------

/**
 * Collapse function
 * @return {void}
 */
Prestations.collapsing = function(element) {
  Prestations.$thisWrapper = $(element).closest(Prestations.wrapper);

  if (!Prestations.$thisWrapper.hasClass('is-active')) {
    // Open
    $(Prestations.detail).stop().slideUp(600);
    Prestations.$thisWrapper.find(Prestations.detail).stop().slideDown(600);
    $(Prestations.wrapper).removeClass('is-active').addClass('is-closed');
    Prestations.$thisWrapper.removeClass('is-closed').addClass('is-active');
  } else {
    // Close
    $(Prestations.wrapper).removeClass('is-active is-closed');
    $(Prestations.title).removeClass('is-hover is-not-hover');
    $(Prestations.detail).stop().slideUp(600);
  }
}

/**
 * Items Animation
 * @return {void}
 */
Prestations.itemsFade = function() {
  console.log('PRESTATIONS ANIMATION');
  var itemsFadeTl = new TimelineMax();
  itemsFadeTl.staggerFromTo('.js-prestation-item', 1.2, { opacity: 0, y: 40 }, { opacity: 1, y: 0, ease: Quart.easeOut }, 0.1, 0);
}


/**
 * On Resize
 * @return {void}
 */
Prestations.animationsInit = '';
Prestations.onResize = function() {
  var windowWidth = $(window).outerWidth();

  if (windowWidth > 767 && !Prestations.animationsInit) {

    Prestations.itemsFade();

    // On click
    $(Prestations.title).on('click', function() {
      var dataImage = $(this).attr('data-image');
      $(Prestations.image).removeClass('is-active');

      // collapse slideup
      if (!$(this).closest(Prestations.wrapper).hasClass('is-active')) {
        $(Prestations.image).removeClass('is-active');
        $(Prestations.image + '[data-image="' + dataImage + '"]').addClass('is-active');
        $(Prestations.detail).stop().slideUp(600);
      }
    });

    $('body').on('click', function(e) {
      if ($(e.target).hasClass('js-prestations-list') || $(e.target).hasClass('js-prestations')) {
        $(Prestations.item).removeClass('is-active');
        $(Prestations.detail).stop().slideUp(600);
      }
    });

    // On hover
    $(Prestations.item).hover(function() {
      if ($(Prestations.item + '.is-active').length < 1) {
        var dataImage = $(this).find(Prestations.title).attr('data-image');
        $(Prestations.image).removeClass('is-active');
        $(Prestations.image + '[data-image="' + dataImage + '"]').addClass('is-active');
      }
    }, function() {
      $(Prestations.image).removeClass('is-active');
    });

    Prestations.animationsInit = true;
  }

  // Mobile
  if (windowWidth < 768 && Prestations.animationsInit) {
    Prestations.animationsInit = false;
  }
}

/**
 * Init
 * @return {void}
 */
Prestations.init = function()  {
  // Animations Controller
  Prestations.animationsController = new ScrollMagic.Controller({
    refreshInterval: 1
  });

  //-- Collapse
  $(Prestations.button).on('click', function() {
    Prestations.collapsing(this);
  });

  //-- If only 1 text column
  $(Prestations.editor).each(function() {
    if ($(this).find('br').length < 1) {
      $(this).addClass('one-column');
    }
  });

  // On resize
  // Set variable if desktop or tablet
  if ($(window).outerWidth() > 767) {
    Prestations.animationsInit = false;
  } else {
    Prestations.animationsInit = true;
  }

  Prestations.onResize();

  $(window).resize(function() {
    Prestations.onResize();
  });
}

//--------------
// Export
//--------------

module.exports = Prestations;