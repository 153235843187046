var Header = {};


//--------------
// Functions
//--------------

Header.socialNetworks = '.js-main-social';
Header.socialNetworksButton = '.js-main-social-button';
Header.openClass = 'is-open';


/**
 * Social Networks - Toggle
 * @return {void}
 */
Header.socialNetworksToggle = function() {
  $(Header.socialNetworks).toggleClass('is-open');
}

/**
 * Social Networks - Close
 * @return {void}
 */
Header.closeSocialNetworks = function() {
  $(Header.socialNetworks).removeClass('is-open');
}

/**
 * Social Networks - Open
 * @return {void}
 */
Header.openSocialNetworks = function() {
  $(Header.socialNetworks).addClass('is-open');
}


//--------------
// Execution
//--------------

Header.init = function() {
  //-- Social Networks Display
  $(Header.socialNetworksButton).on('click', function() {
    Header.socialNetworksToggle();
  });
}

Header.init();


module.exports = Header;