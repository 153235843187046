var Mainscreen = {};

//--------------
// Variables
//--------------
Mainscreen.wrapper = '.js-mainscreen';
Mainscreen.bottom = '.mainscreen-trigger';
Mainscreen.Title = '.js-mainscreen-title';
Mainscreen.Subtitle = '.js-mainscreen-subtitle';
Mainscreen.TitleMargin = 50;

Mainscreen.windowCenter = $(window).outerHeight() / 2;

Mainscreen.animationsInit = '';

//--------------
// Methods
//--------------

/**
 * Title Translation
 * @return {void}
 */
Mainscreen.titleTranslation = function() {
  var fixedElementHeight = $(Mainscreen.Title).outerHeight();

  // TimelineMax
  Mainscreen.tlTextTranslation = new TimelineMax();
  Mainscreen.tlTextTranslation
    .fromTo(Mainscreen.Title, 1, { y: 0, ease: Quart.easeOut }, { y: -fixedElementHeight * 3, ease: Quart.easeOut }, 0);

  // Add to ScrollMagic
  Mainscreen.scene = new ScrollMagic.Scene({
      triggerElement: Mainscreen.bottom,
      duration: $(Mainscreen.wrapper).height() * 3,
      triggerHook: 0.85
    })
    .setTween(Mainscreen.tlTextTranslation)
    // .addIndicators({name: "SCROLL"}) // add indicators (requires plugin)
    .addTo(Mainscreen.animationsController);
}

/**
 * Title Opacity
 * @return {void}
 */
Mainscreen.titleOpacity = function() {
  // TimelineMax
  Mainscreen.tlTextOpacity = new TimelineMax();
  Mainscreen.tlTextOpacity
    .fromTo(Mainscreen.Title, 1, { opacity: 1, ease: Quart.easeOut }, { opacity: 0, ease: Quart.easeOut }, 0);

  // Add to ScrollMagic
  Mainscreen.scene = new ScrollMagic.Scene({
      triggerElement: Mainscreen.bottom,
      duration: $(Mainscreen.wrapper).height(),
      triggerHook: 0.5
    })
    .setTween(Mainscreen.tlTextOpacity)
    // .addIndicators({name: "SCROLL"}) // add indicators (requires plugin)
    .addTo(Mainscreen.animationsController);
}


/**
 * Destroy animations
 * @return {void}
 */
Mainscreen.destroyAnimations = function() {
  // Image
  TweenMax.killTweensOf(Mainscreen.Title);
  TweenMax.set(Mainscreen.Title, { clearProps: 'all' });
}

/**
 * On Resize
 * @return {void}
 */
Mainscreen.animationsInit = false;
Mainscreen.onResize = function() {
  var windowWidth = $(window).outerWidth();

  if (windowWidth > 767 && !Mainscreen.animationsInit) {

    // Fixed Title + Subtitle Position on load
    var titleHeight = $(Mainscreen.Title).outerHeight();
    var subtitleHeight = $(Mainscreen.Subtitle).outerHeight();
    var blockTitleHeight = titleHeight + subtitleHeight + Mainscreen.TitleMargin;

    $(Mainscreen.Title).css({ top: Mainscreen.windowCenter - blockTitleHeight / 2 });
    $(Mainscreen.Subtitle).css({ top: Mainscreen.windowCenter - (blockTitleHeight / 2 - (blockTitleHeight - subtitleHeight)) });

    // Fixed Title Position on scroll with Scrollbar
    App.scrollbar.addListener(function(status) {
      var offset = status.offset;
      var titlePosition = offset.y + Mainscreen.windowCenter - blockTitleHeight / 2;

      $(Mainscreen.Title).css({ top: titlePosition });
    });

    // Title Scroll
    Mainscreen.titleTranslation();
    Mainscreen.titleOpacity();

    Mainscreen.animationsInit = true;
  }

  // Mobile
  if (windowWidth < 768 && Mainscreen.animationsInit) {
    // Destroy Animations
    Mainscreen.destroyAnimations();

    Mainscreen.animationsInit = false;
  }
}

/**
 * Init
 * @return {void}
 */
Mainscreen.init = function()  {
  // Animations Controller
  Mainscreen.animationsController = new ScrollMagic.Controller({
    refreshInterval: 1
  });

  //-- On resize
  // Set variable if desktop or tablet
  if ($(window).outerWidth() > 767) {
    Mainscreen.animationsInit = false;
  } else {
    Mainscreen.animationsInit = true;
  }
  Mainscreen.onResize();

  $(window).on('resize', function() {
    Mainscreen.onResize();
  });
}

//--------------
// Export
//--------------

module.exports = Mainscreen;